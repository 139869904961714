import onLoadAsync from '../shared/on_load_async.js';

onLoadAsync(() => {
  document.querySelectorAll('.parrainage-links .js-share-link').forEach((link) => {
    link.addEventListener('click', (event) => {
      event.preventDefault(); // Avoid going to the top of the page when opening the menu
      navigator.clipboard.writeText(link.href);
    });
  });
});
